import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { Deal } from '../../../../data/Deals';
import { dropdownMenuWithdrawData } from '../../../../data/selects/DropdownMenuWithdrawData';
import { dealData } from '../../../../data/states/DealData';
import { DEAL_REVIEW } from '../../../../data/states/States';
import { setActiveWithdrawState } from '../../../../features/activeState/activeWithdrawStateSlice';
import { setReceiveSum } from '../../../../features/receiveSumSlice';
import { TonalButton } from '../../../buttons/TonalButton';
import { OutlinedCard } from '../../../cards/OutlinedCard';
import { EstimatedIncome } from '../../../estimatedComponents/EsimatedIncome';
import { USDTIcon, WalletIcon } from '../../../Icons';
import { NotificationSmall } from '../../../notifications/NotificationSmall';
import { DropdownMenuWithdraw } from '../../../selects/DropdownMenuWithdraw';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { contractAddressDsf, abiDsf } from '../../../../data/smartcontracts/SmartContractData';
import { setEstimatedIncome } from '../../../../features/estimatedIncomeSlice';


export function DealState() {
  const dropdownMenuWithdrawValue: Deal = useSelector((state: RootState) => state.dropdownMenuWithdrawValue);
  const estimatedIncome = useSelector((state: RootState) => state.estimatedIncome.value);
  const receiveSum = useSelector((state: RootState) => state.receiveSum.value);

  const [list, setList] = React.useState<Array<Deal>>([{
    icon: USDTIcon(),
    name: "USDT",
    count: "0",
  }]);

  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(setActiveWithdrawState(DEAL_REVIEW));
  }

  async function getAccounts() {
    try {
      return await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.log(error);
    }
  }

  const getWithdrawBalance = async () => {
    if ((window as any).ethereum) {
      var web3 = new Web3(Web3.givenProvider);
      var contract = await new web3.eth.Contract(abiDsf as AbiItem[], contractAddressDsf);
      const fromAddress = (await getAccounts())[0];

      try {
        //Баланс
        await contract.methods.balanceOf(fromAddress).call({from: fromAddress})
        .then(function(result : any){
          result = web3.utils.fromWei(result, 'ether');
          const balanceDsf = result;
          console.log("Address = " + fromAddress);
          console.log("Balance = " + result);
          
          //totalSupply
          contract.methods.totalSupply().call({from: fromAddress})
          .then(function(totalSupplyDsf : any){
            totalSupplyDsf = web3.utils.fromWei(totalSupplyDsf, 'ether');
            console.log("Total Supply = " + totalSupplyDsf);
            
            contract.methods.totalDeposited().call({from: fromAddress})
            .then(function(totalDepositedDsf : any){
              totalDepositedDsf = web3.utils.fromWei(totalDepositedDsf, 'ether');
              console.log("Total Deposited = " + totalDepositedDsf);

              contract.methods.lpPrice().call({from: fromAddress})
              .then(function(lpPriceDsf : any){
                lpPriceDsf = web3.utils.fromWei(lpPriceDsf, 'ether');
                console.log("Lp Price = " + lpPriceDsf);  
                
                const realBalance = parseFloat(totalDepositedDsf) * parseFloat(balanceDsf) / parseFloat(totalSupplyDsf);
                console.log("Real balance in USDT = " + realBalance);
                
                const realEstimateIncome = parseFloat(lpPriceDsf) * parseFloat(balanceDsf) - realBalance;
                console.log("Real Estimate Income = " + realEstimateIncome);

                let realBalanceStr = String(realBalance);

                if (realBalanceStr.length <= 8)
                {

                } else {
                  const intBallance = parseInt(realBalanceStr);
                  const amountInts = String(intBallance).length + 1 + 6;
                  realBalanceStr = realBalanceStr.substring(0, amountInts);
                  console.log(realBalanceStr);
                }
      
                const newDeal: Deal = {
                  icon: USDTIcon(),
                  name: "USDT",
                  count: realBalanceStr,
                }
                const newList: Array<Deal> = [newDeal];
                setList(newList);

                dispatch(setEstimatedIncome(realEstimateIncome.toFixed(6).toString()));

              });
            });

        });

      });
      } catch (error) {
        console.log(error);
      }
    }
  }

  React.useEffect(() => {
    getWithdrawBalance()
  }, [dropdownMenuWithdrawValue.count]);

  React.useEffect(() => {
    const sum = parseFloat(dropdownMenuWithdrawValue.count ? dropdownMenuWithdrawValue.count : "0") + parseFloat(estimatedIncome);
    dispatch(setReceiveSum(sum.toString()));
  }, [dropdownMenuWithdrawValue.count]);

  return (
    <OutlinedCard avatar={dealData.avatar} title={dealData.title}>
      <Grid container spacing={{ xs: 2, sm: 4, lg: 9 }}>
        <Grid item xs={12} sm={6}>
          <DropdownMenuWithdraw 
            labelName={dropdownMenuWithdrawData.labelName} 
            menuItemsWithIcon={list} />
        </Grid>
        {
          dropdownMenuWithdrawValue.name ?
            <Grid item xs={12} sm={6} spacing={1}>
              <EstimatedIncome income={`${estimatedIncome} ${dropdownMenuWithdrawValue.name}`} />
            </Grid>
          : null
        }
      </Grid>
      {
        dropdownMenuWithdrawValue.name ? 
        <Grid container>
          <Grid item xs={10}>
            <Box mt={2.25}>
              <NotificationSmall icon={WalletIcon()} text={`${receiveSum} ${dropdownMenuWithdrawValue.name}`} helperText={"You will receive"} />
            </Box>
          </Grid>
        </Grid> 
        : null
      }

      <Box mt={dropdownMenuWithdrawValue.name ? 15.75 : 27.5 }>
        <TonalButton
          disabled={dropdownMenuWithdrawValue.name && dropdownMenuWithdrawValue.count === "0" ? true : false}
          text={dealData.buttonText}
          onClick={handleClickNext} />
      </Box>
    </OutlinedCard>
  );
}
